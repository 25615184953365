import React, {
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
} from "react";
import Stack from "react-bootstrap/Stack";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "react-datepicker/dist/react-datepicker.css";

// import topCompanies from "../../data/topCompanies.json";
import Modal from "react-bootstrap/Modal";
import "./home.scss";
import {
  dataExists,
  dateDifferenceInDays,
  formatDate,
  formatDateYYYYMMDD,
  getLastElements,
  nFormatter,
  stringFormatter,
} from "../../helpers";
import { localString } from "../../localization/localString";
import moment from "moment";
import axios from "axios";
import SPSidebar from "../header/Sidebar";
import { apiEndPoints } from "../../services/endpoints";
import { WebService } from "../../services/webServices";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { Typeahead } from "react-bootstrap-typeahead";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import compaines from "../../data/companies.json";
import { Helmet } from "react-helmet";
import { Scatter } from "react-chartjs-2";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip as Tip,
  Filler,
  Legend,
} from "chart.js";
import { Tab, Tabs } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { COLORS } from "../../constants";
import { THEME_COLORS } from "../../colors";
import useDebounce from "./Debounce";

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const EarningsCalendar = ({ lang, mode, user, showSearch = false }) => {
  const [rowData, setRowData] = useState(null);
  const [logoData, setLogoData] = useState(null);
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDesc, setMetaDesc] = useState("");
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [tabKey, setTabKey] = useState("1");
  const [dateCount, setDateCount] = useState(5);
  const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTicker, setSelectedTicker] = useState(null);
  const [gridApi, setGridApi] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [epsGraphData, setEpsGraphData] = useState(null);
  const [revenueGraphData, setRevenueGraphData] = useState(null);
  const [rawEpsGraphData, setRawEpsGraphData] = useState(null);
  const [rawRevenueGraphData, setRawRevenueGraphData] = useState(null);
  const [futureEarningsDate, setFutureEarningsDate] = useState(null);
  const [futureRevEst, setFutureRevEst] = useState(null);
  const [futureEPSEst, setFutureEPSEst] = useState(null);
  const [selectedDuration, setSelectedDuration] = useState("all");
  const gridRef = useRef();
  const debouncedStartdate = useDebounce(startDate, 1300);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tip,
    Filler,
    Legend
  );
  const getHistoricalEarningsData = () => {
    let fullUrl = `${apiEndPoints.historicalEarnings}/${selectedTicker?.symbol}`;
    let headers = {};

    WebService.get(fullUrl, { headers: headers }).then((response) => {
      let data = response?.data;
      data.sort((a, b) => new Date(a.date) - new Date(b.date));

      let revData = [];
      let revEstData = [];
      let _revRawData = {};
      let revLabels = [];
      let epsData = [];
      let epsEstData = [];
      let _epsRawData = {};
      let epsLabels = [];
      let revColors = [];
      let revEstColors = [];
      let epsColors = [];
      let epsEstColors = [];
      let _futureEarningsDate = null;
      let today = new Date();
      let _futureRevEst = null;
      let _futureEPSEst = null;
      data?.forEach((element) => {
        if (element.revenue) {
          revData.push(element.revenue);
          revEstData.push(element.revenueEstimated);
          revLabels.push(element.date);
          let curColor = "grey";
          if (!element?.revenueEstimated) {
            curColor = "grey";
          } else if (
            parseFloat(element.revenue) === parseFloat(element.revenueEstimated)
          ) {
            curColor = "blue";
          } else if (
            parseFloat(element.revenue) >= parseFloat(element.revenueEstimated)
          ) {
            curColor = "green";
          } else if (
            parseFloat(element.revenue) < parseFloat(element.revenueEstimated)
          ) {
            curColor = "red";
          }
          revColors.push(curColor);
          revEstColors.push("white");
          _revRawData[element.date] = {
            estimated: element.revenueEstimated,
            actuals: element.revenue,
          };
        }
        if (element.eps) {
          epsData.push(element.eps);
          epsLabels.push(element.date);
          epsEstData.push(element.epsEstimated);
          let curColor = "grey";
          if (!element?.epsEstimated) {
            curColor = "grey";
          } else if (
            parseFloat(element.eps) === parseFloat(element.epsEstimated)
          ) {
            curColor = "blue";
          } else if (
            parseFloat(element.eps) >= parseFloat(element.epsEstimated)
          ) {
            curColor = "green";
          } else if (
            parseFloat(element.eps) < parseFloat(element.epsEstimated)
          ) {
            curColor = "red";
          }

          epsColors.push(curColor);
          epsEstColors.push("white");

          _epsRawData[element.date] = {
            estimated: element.epsEstimated,
            actuals: element.eps,
          };
        }
        let dataDate = new Date(element.date);

        if (dataDate >= today && dataDate < _futureEarningsDate) {
          _futureEarningsDate = dataDate;
          _futureRevEst = element.revenueEstimated;
          _futureEPSEst = element.epsEstimated;
        } else if (dataDate >= today && _futureEarningsDate == null) {
          _futureEarningsDate = dataDate;
          _futureRevEst = element.revenueEstimated;
          _futureEPSEst = element.epsEstimated;
        }
      });

      setFutureEarningsDate(_futureEarningsDate);
      setFutureRevEst(_futureRevEst);
      setFutureEPSEst(_futureEPSEst);

      let revPayload = {
        labels: revLabels,
        datasets: [
          {
            data: revData,
            borderWidth: 0, // Hide the connecting line
            tension: 0, // No curve smoothing
            pointBackgroundColor: revColors, // Colors for each dot
            pointBorderColor: "black", // Optional border around dots
            pointBorderWidth: 1,
            pointRadius: 5, // Size of each point
          },
          {
            data: revEstData,
            borderWidth: 0, // Hide the connecting line
            tension: 0, // No curve smoothing
            pointBackgroundColor: revEstColors, // Colors for each dot
            pointBorderColor: "black", // Optional border around dots
            pointBorderWidth: 1,
            pointRadius: 5, // Size of each point
          },
        ],
      };
      setRevenueGraphData(revPayload);
      setRawRevenueGraphData(revPayload);
      let epsPayload = {
        labels: epsLabels,
        datasets: [
          {
            data: epsData,
            borderWidth: 0, // Hide the connecting line
            tension: 0, // No curve smoothing
            pointBackgroundColor: epsColors, // Colors for each dot
            pointBorderColor: "black", // Optional border around dots
            pointBorderWidth: 1,
            pointRadius: 5, // Size of each point
          },
          {
            data: epsEstData,
            borderWidth: 0, // Hide the connecting line
            tension: 0, // No curve smoothing
            pointBackgroundColor: epsEstColors, // Colors for each dot
            pointBorderColor: "black", // Optional border around dots
            pointBorderWidth: 1,
            pointRadius: 5, // Size of each point
          },
        ],
      };
      setEpsGraphData(epsPayload);
      setRawEpsGraphData(epsPayload);
    });
  };

  const getNewGraphData = (rawData, count) => {
    let payload = JSON.parse(JSON.stringify(rawData));
    let curLabels = payload["labels"];
    let curData = payload["datasets"][0]["data"];
    let curColors = payload["datasets"][0]["pointBackgroundColor"];
    let curEstData = payload["datasets"][1]["data"];
    let curEstColors = payload["datasets"][1]["pointBackgroundColor"];
    payload["labels"] = getLastElements(curLabels, count);
    payload["datasets"][0]["data"] = getLastElements(curData, count);
    payload["datasets"][0]["pointBackgroundColor"] = getLastElements(
      curColors,
      count
    );
    payload["datasets"][1]["data"] = getLastElements(curEstData, count);
    payload["datasets"][1]["pointBackgroundColor"] = getLastElements(
      curEstColors,
      count
    );
    return payload;
  };
  useEffect(() => {
    if (rawRevenueGraphData) {
      if (selectedDuration === "all") {
        setRevenueGraphData(rawRevenueGraphData);
        setEpsGraphData(rawEpsGraphData);
      } else if (selectedDuration === "5y") {
        let newPayload = getNewGraphData(rawRevenueGraphData, 20);
        setRevenueGraphData(newPayload);
      } else if (selectedDuration === "3y") {
        let newPayload = getNewGraphData(rawRevenueGraphData, 12);
        setRevenueGraphData(newPayload);
      } else if (selectedDuration === "1y") {
        let newPayload = getNewGraphData(rawRevenueGraphData, 4);
        setRevenueGraphData(newPayload);
      }
    }
    if (rawEpsGraphData) {
      if (selectedDuration === "all") {
        setEpsGraphData(rawEpsGraphData);
      } else if (selectedDuration === "5y") {
        let newPayload = getNewGraphData(rawEpsGraphData, 20);
        setEpsGraphData(newPayload);
      } else if (selectedDuration === "3y") {
        let newPayload = getNewGraphData(rawEpsGraphData, 12);
        setEpsGraphData(newPayload);
      } else if (selectedDuration === "1y") {
        let newPayload = getNewGraphData(rawEpsGraphData, 4);
        setEpsGraphData(newPayload);
      }
    }
  }, [selectedDuration]);

  useEffect(() => {
    if (window.innerWidth < 600) {
      setDateCount(1);
    } else if (window.innerWidth < 1199) {
      setDateCount(2);
    } else {
      setDateCount(5);
    }
  }, [window.innerWidth]);

  useEffect(() => {
    let thisDate = new Date(endDate);
    thisDate.setDate(thisDate.getDate() - dateCount + 1);
    setStartDate(thisDate);
    setSelectedDate(thisDate);
  }, [dateCount]);

  useEffect(() => {
    if (showModal === false) {
      setSelectedTicker(null);
      setRevenueGraphData(null);
      setRawRevenueGraphData(null);
      setEpsGraphData(null);
      setRawEpsGraphData(null);
      setFutureEarningsDate(null);
      setFutureEPSEst(null);
      setFutureRevEst(null);
      setSelectedDuration("all");
    }
  }, [showModal]);
  useEffect(() => {
    if (
      selectedTicker !== null &&
      selectedTicker !== undefined &&
      dataExists(selectedTicker)
    ) {
      getHistoricalEarningsData();
      setShowModal(true);
    }
  }, [selectedTicker]);

  const goFront = () => {
    let newEndDate = new Date(endDate);
    newEndDate.setDate(newEndDate.getDate() + dateCount);
    setEndDate(newEndDate);
    let newStartDate = new Date(startDate);
    newStartDate.setDate(newStartDate.getDate() + dateCount);
    setStartDate(newStartDate);
  };

  const goBack = () => {
    let newEndDate = new Date(endDate);
    newEndDate.setDate(newEndDate.getDate() - dateCount);
    let newStartDate = new Date(startDate);
    newStartDate.setDate(newStartDate.getDate() - dateCount);
    setEndDate(newEndDate);
    setStartDate(newStartDate);
  };

  useEffect(() => {
    if (startDate !== null) {
      let formattedStartDate = formatDateYYYYMMDD(startDate);
      let formattedEndDate = formatDateYYYYMMDD(endDate);
      let fullUrl = `${apiEndPoints.earningCalendar}/?start_date=${formattedStartDate}&end_date=${formattedEndDate}`;
      setLoading(true);

      let headers = {
        // "Content-Type": "application/json",
        // Authorization: `Bearer ${user?.token}`,
      };

      WebService.get(fullUrl, { headers: headers }).then((response) => {
        let data = response?.data;
        let formattedData = {};
        let gridData = [];
        data?.forEach((element) => {
          if (formattedData[element.date] === undefined) {
            formattedData[element.date] = [];
          }
          formattedData[element.date].push(element);
          if (element.date === formatDateYYYYMMDD(startDate)) {
            gridData.push(element);
          }
        });
        setRowData(gridData);
        setLogoData(formattedData);

        setLoading(false);
      });
    }
  }, [debouncedStartdate]);

  // const RankColRender = (p) => {
  //   return `${p.node.rowIndex + 1}`;
  // };

  const RevenueRender = (p) => {
    if (p.data?.revenue === undefined) return null;
    if (isNaN(parseFloat(p.data?.revenue))) return "-";
    let cur = p.data?.currency === "USD" ? "$" : `${p.data?.currency} `;
    // return `${cur}${nFormatter(p.data?.revenue, 4)}`;
    let curRev = parseFloat(p.data?.revenue);
    curRev = isNaN(curRev) ? 0 : curRev;
    let curRevEst = parseFloat(p.data?.revenueEstimated);
    curRevEst = isNaN(curRevEst) ? 0 : curRevEst;

    return (
      <div>
        {curRev >= curRevEst ? (
          <span className="chart-numbers-change-green">
            <i className="bi bi-arrow-up-short"></i>
            {`${cur}${nFormatter(curRev, 4)}`}
          </span>
        ) : (
          <span className="chart-numbers-change-red">
            <i className="bi bi-arrow-down-short"></i>
            {`${cur}${nFormatter(curRev, 4)}`}
          </span>
        )}
      </div>
    );
  };
  const EPSRender = (p) => {
    if (p.data?.eps === undefined) return null;
    if (isNaN(parseFloat(p.data?.eps))) return "-";
    let curEPS = parseFloat(p.data?.eps);
    curEPS = isNaN(curEPS) ? 0 : curEPS;
    let curEPSEst = parseFloat(p.data?.epsEstimated);
    curEPSEst = isNaN(curEPSEst) ? -999999999999999 : curEPSEst;

    return (
      <div>
        {curEPS >= curEPSEst ? (
          <span className="chart-numbers-change-green">
            <i className="bi bi-arrow-up-short"></i>
            {`${curEPS}`}
          </span>
        ) : (
          <span className="chart-numbers-change-red">
            <i className="bi bi-arrow-down-short"></i>
            {`${curEPS}`}
          </span>
        )}
      </div>
    );
  };
  const MarketCapRender = (p) => {
    if (p.data?.market_cap === undefined) return null;
    return `$${nFormatter(p.data?.market_cap, 4)}`;
  };
  const FiscalDateEndingRender = (p) => {
    if (p.data?.fiscalDateEnding === undefined) return null;
    return `${formatDate(p.data?.fiscalDateEnding)}`;
  };

  const EstimatedRevenueRender = (p) => {
    if (p.data?.revenueEstimated === undefined) return null;
    let cur = p.data?.currency === "USD" ? "$" : `${p.data?.currency} `;
    return `${cur}${nFormatter(p.data?.revenueEstimated, 4)}`;
  };
  const EstimatedEPSRender = (p) => {
    if (p.data?.epsEstimated === undefined) return "-";
    // let cur = p.data?.currency === "USD" ? "$" : `${p.data?.currency} `;
    return p.data?.epsEstimated;
  };
  const NameRender = (p) => {
    return (
      <a
        className="link-underline cursor-pointer"
        onClick={() => {
          setSelectedTicker(p.data);
        }}
      >
        {p.data.company_name}
      </a>
    );
  };
  const TimeRender = (p) => {
    if (p.data?.time === "bmo") {
      return "Before Market Open";
    } else if (p.data?.time === "amc") {
      return "After Market Close";
    } else {
      return "--";
    }
  };

  const RevenueBeatRender = (p) => {
    if (p.data?.revenue === undefined) return "-";
    if (isNaN(parseFloat(p.data?.revenue))) return "-";
    // return `${cur}${nFormatter(p.data?.revenue, 4)}`;
    let curRev = parseFloat(p.data?.revenue);
    curRev = isNaN(curRev) ? 0 : curRev;
    let curRevEst = parseFloat(p.data?.revenueEstimated);
    curRevEst = isNaN(curRevEst) ? 0 : curRevEst;
    let res = "Inline";
    let colorClass = "";
    if (curRevEst > curRev) {
      res = "Missed";
      colorClass = "chart-numbers-change-red";
    } else if (curRev > curRevEst) {
      res = "Beat";
      colorClass = "chart-numbers-change-green";
    }

    return (
      <div>
        <span className={colorClass}>{res}</span>
      </div>
    );
  };
  const EPSBeatRender = (p) => {
    if (p.data?.eps === undefined) return "-";
    if (isNaN(parseFloat(p.data?.eps))) return "-";
    // return `${cur}${nFormatter(p.data?.revenue, 4)}`;
    let curEPS = parseFloat(p.data?.eps);
    curEPS = isNaN(curEPS) ? 0 : curEPS;
    let curEPSEst = parseFloat(p.data?.epsEstimated);
    curEPSEst = isNaN(curEPSEst) ? -999999999999999 : curEPSEst;
    let res = "Inline";
    let colorClass = "";
    if (curEPSEst > curEPS) {
      res = "Missed";
      colorClass = "chart-numbers-change-red";
    } else if (curEPS > curEPSEst) {
      res = "Beat";
      colorClass = "chart-numbers-change-green";
    }

    return (
      <div>
        <span className={colorClass}>{res}</span>
      </div>
    );
  };

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "time",
      headerName: localString[lang].time,
      minWidth: 200,
      cellRenderer: TimeRender,
      // maxWidth: 80,
      sortable: false,
    },
    {
      field: "company_name",
      headerName: localString[lang].companyName,
      cellRenderer: NameRender,
      minWidth: 200,
      sortable: false,
    },
    {
      field: "symbol",
      headerName: localString[lang].symbol,
      minWidth: 100,
      // maxWidth: 120,
      sortable: false,
    },
    {
      field: "market_cap",
      headerName: localString[lang].marketCap,
      cellRenderer: MarketCapRender,
      minWidth: 120,
      sortable: true,
    },
    {
      field: "fiscalDateEnding",
      headerName: localString[lang].fiscalDateEnding,
      cellRenderer: FiscalDateEndingRender,
      minWidth: 120,
      sortable: true,
    },
    {
      field: "revenueEstimated",
      headerName: localString[lang].estimatedRevenue,
      cellRenderer: EstimatedRevenueRender,
      minWidth: 150,
      // maxWidth: 150,
      sortable: true,
    },
    {
      field: "revenue",
      headerName: localString[lang].Revenue,
      cellRenderer: RevenueRender,
      minWidth: 150,
      sortable: true,
    },
    {
      field: "revenue",
      headerName: localString[lang].RevenueBeat,
      cellRenderer: RevenueBeatRender,
      minWidth: 150,
      sortable: true,
    },
    {
      field: "epsEstimated",
      headerName: localString[lang].estimatedEPS,
      cellRenderer: EstimatedEPSRender,
      minWidth: 150,
      sortable: true,
    },
    {
      field: "eps",
      headerName: localString[lang].EPS,
      cellRenderer: EPSRender,
      minWidth: 150,
      sortable: true,
    },
    {
      field: "eps",
      headerName: localString[lang].EPSBeat,
      cellRenderer: EPSBeatRender,
      minWidth: 150,
      sortable: true,
    },
  ]);

  const optionsRev = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1,
    scales: {
      x: {
        // grid: {
        //   color:
        //     mode === "dark"
        //       ? "rgba(255, 255, 255, 0.15)"
        //       : "rgba(0, 0, 0, 0.1)",
        // },
        ticks: {
          callback: function (value, index, ticks) {
            let date = new Date(this.getLabelForValue(value));
            let dateFormat = "[Q]Q-YYYY";
            let thisDate = moment(date).format(dateFormat);
            return thisDate;
          },
        },
      },

      y: {
        grid: {
          color:
            mode === "dark"
              ? "rgba(255, 255, 255, 0.15)"
              : "rgba(0, 0, 0, 0.1)",
        },
        ticks: {
          callback: function (label, index, labels) {
            // if (yaxisFormatter) {
            //   return yaxisFormatter(label);
            // }
            if (parseFloat(label) >= 0) {
              return `$${nFormatter(label)}`;
            } else {
              return `-$${nFormatter(parseFloat(label) * -1)}`;
            }
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        // align: 'start',
        color: THEME_COLORS[mode].graph.title,
        // text: `${title}`,
        font: {
          size: 18,
        },
      },
      tooltip: {
        titleFont: {
          size: 15,
        },
        callbacks: {
          label: function (tooltipItem, data) {
            // return [
            //   `${localString[lang].Revenue}: $${nFormatter(
            //     tooltipItem.raw,
            //     4
            //   )}`,
            //   `${localString[lang].estimatedRevenue}: $${nFormatter(
            //     rawRevenueGraphData[tooltipItem.label].estimated,
            //     4
            //   )}`,
            // ];
            return `${
              tooltipItem.datasetIndex === 0
                ? localString[lang].Revenue
                : localString[lang].estimatedRevenue
            }: $${nFormatter(tooltipItem.raw, 4)}`;
          },
        },
      },
    },
  };

  const optionsEps = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1,
    scales: {
      x: {
        // grid: {
        //   color:
        //     mode === "dark"
        //       ? "rgba(255, 255, 255, 0.15)"
        //       : "rgba(0, 0, 0, 0.1)",
        // },
        ticks: {
          callback: function (value, index, ticks) {
            let date = new Date(this.getLabelForValue(value));
            let dateFormat = "[Q]Q-YYYY";
            let thisDate = moment(date).format(dateFormat);
            return thisDate;
          },
        },
      },
      y: {
        grid: {
          color:
            mode === "dark"
              ? "rgba(255, 255, 255, 0.15)"
              : "rgba(0, 0, 0, 0.1)",
        },
        ticks: {
          callback: function (label, index, labels) {
            return `${parseFloat(label).toFixed(2)}`;
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        // align: 'start',
        color: THEME_COLORS[mode].graph.title,
        // text: `${title}`,
        font: {
          size: 18,
        },
      },
      tooltip: {
        titleFont: {
          size: 15,
        },
        callbacks: {
          label: function (tooltipItem, data) {
            let dispValue = parseFloat(tooltipItem.raw).toFixed(4);
            dispValue = isNaN(dispValue) ? "NA" : dispValue;

            return `${
              tooltipItem.datasetIndex === 0
                ? localString[lang].EPS
                : localString[lang].estimatedEPS
            }: ${dispValue}`;
          },
        },
      },
    },
  };

  const filterDate = (curDate) => {
    setSelectedDate(new Date(curDate));
    if (logoData[curDate]) {
      setRowData(logoData[curDate]);
    } else {
      setRowData([]);
    }
    // let fullUrl = `${apiEndPoints.earningCalendar}/?start_date=${curDate}&end_date=${curDate}`;
    // setSelectedDate(new Date(curDate));
    // setLoading(true);

    // let headers = {};

    // WebService.get(fullUrl, { headers: headers }).then((response) => {
    //   let data = response?.data;
    //   setRowData(data);
    //   setLoading(false);
    // });
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  return (
    <>
      {startDate && (
        <>
          <Helmet>
            <title>{metaTitle}</title>
            <meta name="description" content={metaDesc} />
          </Helmet>

          {startDate && dateCount !== 1 && (
            <Stack gap={3} className="m-3">
              <InputGroup className="w-25 align-self-lg-end">
                <Form.Control
                  type="date"
                  value={formatDateYYYYMMDD(startDate)}
                  onChange={(e) => {
                    let newDate = new Date(e.target.value);
                    let newEndDate = new Date(newDate);
                    newEndDate.setDate(newEndDate.getDate() + dateCount);
                    setEndDate(newEndDate);
                    setStartDate(newDate);
                  }}
                  aria-label="Select date"
                />
              </InputGroup>
            </Stack>
          )}

          <Stack className="content" gap={3}>
            <div className="slider-container p-1">
              <div className="mt-3 cursor-pointer" onClick={() => goBack()}>
                <i className="bi bi-chevron-left mt-2"></i>
              </div>
              {[...Array(dateCount)].map((index, item) => {
                let thisDate = new Date(startDate);
                thisDate.setDate(thisDate.getDate() + item);
                let logoItems =
                  logoData && logoData[formatDateYYYYMMDD(thisDate)];

                return (
                  <div
                    key={item}
                    className={`bd-highlight slider-flex-column ${
                      item < dateCount - 1 ? "border-end" : ""
                    }`}
                  >
                    <div className="slider-heading border-bottom">
                      <div className="screener-title">
                        {days[thisDate.getDay()]}
                      </div>
                      <div>{formatDate(thisDate)}</div>
                    </div>
                    <div className={"logo-container pt-3 pb-3"}>
                      <div className="logo-column">
                        {logoItems?.map((item, index) => {
                          return index > 15 ? null : (
                            <div
                              onClick={() => {
                                setSelectedTicker(item);
                              }}
                              key={`${item["symbol"]}-${index}`}
                              className="w-50 cursor-pointer p-3"
                            >
                              <div className="p-1 border shadow-lg border-radius-5">
                                <img
                                  className="border-radius-5"
                                  src={`https://financialmodelingprep.com/image-stock/${item["symbol"]}.png`}
                                  // alt={`${item["symbol"]}`}
                                  onError={(e) => {
                                    e.target.onerror = null; // Prevent infinite loop in case the fallback also fails
                                    e.target.src = "/assets/na.png";
                                  }}
                                />
                                <p className="text-center m-0">{`${item["symbol"]}`}</p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                );
              })}
              <div className="mt-3 cursor-pointer" onClick={() => goFront()}>
                <i className="bi bi-chevron-right"></i>
              </div>
            </div>
          </Stack>

          <Stack
            className={`content ${
              mode === "dark" ? "background-dark" : "background-light"
            }`}
            gap={3}
          >
            <ButtonGroup aria-label="Date Selections">
              {[...Array(dateCount)].map((index, item) => {
                let thisDate = new Date(startDate);
                thisDate.setDate(thisDate.getDate() + item);
                return (
                  <Button
                    key={item}
                    variant={`secondary ${
                      formatDateYYYYMMDD(thisDate) ===
                      formatDateYYYYMMDD(selectedDate)
                        ? "text-bg-dark"
                        : ""
                    }`}
                    onClick={() => {
                      if (dateCount === 1) {
                        setShowCalendar(true);
                      } else {
                        filterDate(formatDateYYYYMMDD(thisDate));
                      }
                    }}
                  >
                    {formatDate(thisDate)}
                    {dateCount === 1 && (
                      <i className="ms-2 bi bi-calendar3"></i>
                    )}
                  </Button>
                );
              })}
            </ButtonGroup>
            {showCalendar && (
              <div className="m-auto">
                <DatePicker
                  selected={selectedDate}
                  onChange={(newDate) => {
                    setShowCalendar(false);
                    setSelectedDate(newDate);
                    setStartDate(newDate);
                    setEndDate(newDate);
                  }}
                  inline // Show calendar inline
                  // ="custom-datepicker"
                />
              </div>
            )}
          </Stack>
          <Stack
            className={`content ${
              mode === "dark" ? "background-dark" : "background-light"
            }`}
            gap={3}
          >
            <div
              className={`ag-theme-${
                mode === "dark" ? "quartz-dark" : "quartz"
              }`}
              style={{ height: "calc(100vh - 6rem)" }}
            >
              <AgGridReact
                ref={gridRef}
                loading={loading}
                rowData={rowData}
                columnDefs={columnDefs}
                autoSizeStrategy={{ type: "fitGridWidth" }}
                onGridReady={onGridReady}
                pagination={true}
                paginationPageSize={25}
                paginationPageSizeSelector={[25, 50, 100]}
              />
            </div>
          </Stack>

          <Modal
            show={showModal}
            fullscreen={true}
            onHide={() => setShowModal(false)}
            dialogClassName="slider-modal"
          >
            <Modal.Header closeButton>
              {/* <Modal.Title>Modal</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div
                className={`${
                  mode === "dark" ? "background-dark" : "background-light"
                }`}
              >
                <div className="mt-3 pt-3 pb-2 d-flex justify-content-center align-items-center company-name">
                  <img
                    className="cursor-pointer modal-image"
                    src={`https://financialmodelingprep.com/image-stock/${selectedTicker?.symbol}.png`}
                    // alt={`${selectedTicker?.symbol}`}
                    onError={(e) => {
                      e.target.onerror = null; // Prevent infinite loop in case the fallback also fails
                      e.target.src = "/assets/na.png";
                    }}

                    // onClick={() => {
                    //   setSelectedTicker(item);
                    // }}
                  />
                </div>

                <div className="pb-2 d-flex justify-content-center align-items-center company-name">
                  {selectedTicker?.company_name}
                  <a
                    target="__blank"
                    href={`/companies/stock-metrics/${selectedTicker?.symbol}`}
                  >
                    <i className="bi bi-box-arrow-up-right ms-2 small"></i>
                  </a>
                </div>
                <div className="pb-3 d-flex justify-content-center align-items-center search-data">
                  {`$${selectedTicker?.price}`}
                  <div
                    className={
                      parseFloat(selectedTicker?.price) > 0
                        ? "search-sub-data-green"
                        : "search-sub-data-red"
                    }
                  >
                    {`$${parseFloat(
                      Math.abs(selectedTicker?.price_changes)
                    ).toFixed(2)} | ${parseFloat(
                      Math.abs(selectedTicker?.price_changes_pct)
                    ).toFixed(2)}%`}
                  </div>
                </div>
              </div>

              {futureEarningsDate && (
                <div
                  className={`${
                    mode === "dark" ? "background-dark" : "background-light"
                  }`}
                >
                  <div className="mt-3 p-3">
                    <div className="company-name text-md-start">{`Next Earnings in ${dateDifferenceInDays(
                      futureEarningsDate,
                      new Date()
                    )} days`}</div>
                    <div className="text-body-tertiary font-1">
                      {formatDate(futureEarningsDate)}
                    </div>
                  </div>

                  <div className="pb-3 d-flex justify-content-center align-items-center search-data">
                    <Button variant="info" size="sm">{`${
                      futureRevEst
                        ? "$" + nFormatter(futureRevEst, 2) + " (Revenue est)"
                        : "NA"
                    }`}</Button>
                    <Button variant="info" size="sm" className="ms-2">{`${
                      futureEPSEst ? futureEPSEst + " (EPS est)" : "NA"
                    }`}</Button>
                  </div>
                </div>
              )}
              <div
                className={`mt-2 ${
                  mode === "dark" ? "background-dark" : "background-light"
                }`}
              >
                <Tabs
                  id="controlled-tab-example"
                  activeKey={tabKey}
                  onSelect={(k) => setTabKey(k)}
                  className="mb-3"
                >
                  <Tab eventKey="1" title={localString[lang].historicalRevenue}>
                    <div className="p-2 text-end">
                      <ButtonGroup size="sm" aria-label="Data Selections">
                        <Button
                          variant="secondary"
                          className={`${
                            selectedDuration === "all" ? "text-bg-light" : ""
                          }`}
                          onClick={() => {
                            setSelectedDuration("all");
                          }}
                        >
                          All
                        </Button>
                        <Button
                          variant="secondary"
                          className={`border-start ${
                            selectedDuration === "5y" ? "text-bg-light" : ""
                          }`}
                          onClick={() => {
                            setSelectedDuration("5y");
                          }}
                        >
                          5Y
                        </Button>
                        <Button
                          variant="secondary"
                          className={`border-start ${
                            selectedDuration === "3y" ? "text-bg-light" : ""
                          }`}
                          onClick={() => {
                            setSelectedDuration("3y");
                          }}
                        >
                          3Y
                        </Button>
                        <Button
                          variant="secondary"
                          className={`border-start ${
                            selectedDuration === "1y" ? "text-bg-light" : ""
                          }`}
                          onClick={() => {
                            setSelectedDuration("1y");
                          }}
                        >
                          1Y
                        </Button>
                      </ButtonGroup>
                    </div>
                    <div className="modal-graph p-2">
                      {/* <Scatter options={options} data={data} /> */}
                      {revenueGraphData && (
                        <Line data={revenueGraphData} options={optionsRev} />
                      )}
                    </div>
                  </Tab>
                  <Tab eventKey="2" title={localString[lang].historicalEPS}>
                    <div className="p-2 text-end">
                      <ButtonGroup size="sm" aria-label="Data Selections">
                        <Button
                          variant="secondary"
                          className={`${
                            selectedDuration === "all" ? "text-bg-light" : ""
                          }`}
                          onClick={() => {
                            setSelectedDuration("all");
                          }}
                        >
                          All
                        </Button>
                        <Button
                          variant="secondary"
                          className={`border-start ${
                            selectedDuration === "5y" ? "text-bg-light" : ""
                          }`}
                          onClick={() => {
                            setSelectedDuration("5y");
                          }}
                        >
                          5Y
                        </Button>
                        <Button
                          variant="secondary"
                          className={`border-start ${
                            selectedDuration === "3y" ? "text-bg-light" : ""
                          }`}
                          onClick={() => {
                            setSelectedDuration("3y");
                          }}
                        >
                          3Y
                        </Button>
                        <Button
                          variant="secondary"
                          className={`border-start ${
                            selectedDuration === "1y" ? "text-bg-light" : ""
                          }`}
                          onClick={() => {
                            setSelectedDuration("1y");
                          }}
                        >
                          1Y
                        </Button>
                      </ButtonGroup>
                    </div>

                    <div className="modal-graph p-2">
                      {/* <Scatter options={options} data={data} /> */}
                      {epsGraphData && (
                        <Line data={epsGraphData} options={optionsEps} />
                      )}
                    </div>
                  </Tab>
                </Tabs>
                    <div className="p-3 d-flex flex-wrap">
                      <div className="w-50">
                        <i
                          className="bi bi-circle-fill small"
                          style={{ color: "green" }}
                        ></i>
                        <span className="ms-2 font-14">{localString[lang]['beat']}</span>
                      </div>
                      <div className="w-50">
                        <i
                          className="bi bi-circle-fill small"
                          style={{ color: "red" }}
                        ></i>
                        <span className="ms-2 font-14">{localString[lang]['miss']}</span>
                      </div>
                      <div className="w-50">
                        <i
                          className="bi bi-circle-fill small"
                          style={{ color: "blue" }}
                        ></i>
                        <span className="ms-2 font-14">{localString[lang]['equal']}</span>
                      </div>
                      <div className="w-50">
                        <i
                          className="bi bi-circle-fill small"
                          style={{ color: "white" }}
                        ></i>
                        <span className="ms-2 font-14">{localString[lang]['estimatedValue']}</span>
                      </div>
                      <div className="w-100">
                        <i
                          className="bi bi-circle-fill small"
                          style={{ color: "grey" }}
                        ></i>
                        <span className="ms-2 font-14">{localString[lang]['estimateNA']}</span>
                      </div>
                    </div>
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
};

export default EarningsCalendar;
