import React, { StrictMode, useEffect, useMemo, useState, useRef } from "react";
import { useSearchParams, useParams, useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "./home.scss";
import { dataExists, formatDate, nFormatter } from "../../helpers";
import { localString } from "../../localization/localString";
import moment from "moment";
import axios from "axios";
import { WebService } from "../../services/webServices";
import { apiEndPoints } from "../../services/endpoints";
import { LANGUAGES, scannerOptions } from "../../constants";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import { Helmet } from "react-helmet";
import Pagination from "react-bootstrap/Pagination";
import PaginationComponent from "./Paginator";
import ForumComments from "./ForumComments";
import TopContributers from "./TopContributers";
import Parser from "html-react-parser";

const PenguinChatMessage = ({ lang, mode, user, message }) => {
  // const [searchParams, setSearchParams] = useSearchParams();
  // const [metaTitle, setMetaTitle] = useState(null);
  // const [metaDesc, setMetaDesc] = useState(null);
  // const [loggedin, setLoggedin] = useState(false);
  // const [pageNumber, setPageNumber] = useState(1);
  // const [pageSize, setPageSize] = useState(5);
  // const [totalComments, setTotalComments] = useState(0);
  // const [userComment, setUserComment] = useState("");
  // const [allComments, setAllComments] = useState([]);
  // const [questionDetails, setQuestionDetails] = useState(null);
  // const [following, setFollowing] = useState(false);
  // const [userid, setUserid] = useState(false);
  // const [me, setMe] = useState(false);

  // const [upvoted, setUpvoted] = useState(false);
  // const [downvoted, setDownvoted] = useState(false);
  // const [upvoteCount, setUpvoteCount] = useState(0);
  // const [downvoteCount, setDownvoteCount] = useState(0);
  // const [userVotetype, setUserVotetype] = useState(null);

  // const navigate = useNavigate();
  // const { slug } = useParams();

  // const loadQuestionData = async () => {
  //   let fullUrl = `${apiEndPoints.getQuestions}/${slug}/`;

  //   let headers = {
  //     "Content-Type": "application/json",
  //   };
  //   if (dataExists(user)) {
  //     headers["Authorization"] = `Bearer ${user?.token}`;
  //   }

  //   WebService.get(fullUrl, { headers }).then((response) => {
  //     let _data = response?.data;
  //     if (_data) {
  //       console.log(_data);
  //       setQuestionDetails(_data);
  //       setFollowing(_data.following);
  //       setUserid(_data.userid);
  //       if (dataExists(user) && user.username === _data.username) {
  //         setMe(true);
  //       }
  //     }
  //   });
  // };

  // const vote = async (type) => {
  //   if (dataExists(user)) {
  //     let fullUrl = `${apiEndPoints.vote}/`;

  //     let headers = {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${user?.token}`,
  //     };

  //     let payload = {
  //       content_type: "question",
  //       object_id: questionDetails.id,
  //       vote_type: type,
  //     };
  //     let response = await WebService.post(fullUrl, payload, {
  //       headers: headers,
  //     });
  //     if (response?.status >= 200 && response?.status < 300) {
  //       if (userVotetype === true) {
  //         if (type === true) {
  //           // Delete UpVote
  //           setUserVotetype(null);
  //           setUpvoted(false);
  //           setUpvoteCount(upvoteCount - 1);
  //         } else {
  //           // Change to DownVote
  //           setUserVotetype(false);
  //           setUpvoted(false);
  //           setUpvoteCount(upvoteCount - 1);
  //           setDownvoteCount(downvoteCount + 1);
  //           setDownvoted(true);
  //         }
  //       } else if (userVotetype === false) {
  //         if (type === true) {
  //           // Change to UpVote
  //           setUserVotetype(true);
  //           setUpvoted(true);
  //           setUpvoteCount(upvoteCount + 1);
  //           setDownvoteCount(downvoteCount - 1);
  //           setDownvoted(false);
  //         } else {
  //           // Delete Downvote
  //           setUserVotetype(null);
  //           setDownvoteCount(downvoteCount - 1);
  //           setDownvoted(false);
  //         }
  //       } else {
  //         if (type === true) {
  //           setUpvoted(true);
  //           setUserVotetype(true);
  //           setUpvoteCount(upvoteCount + 1);
  //         } else {
  //           setDownvoteCount(downvoteCount + 1);
  //           setDownvoted(true);
  //           setUserVotetype(false);
  //         }
  //       }
  //     }
  //   }
  // };

  // const loadCommentsData = async () => {
  //   let fullUrl = `${apiEndPoints.getAnswers}/?page=${pageNumber}&page_size=${pageSize}&question_id=${questionDetails?.id}`;
  //   let headers = {
  //     "Content-Type": "application/json",
  //   };
  //   if (dataExists(user)) {
  //     headers["Authorization"] = `Bearer ${user?.token}`;
  //   }

  //   WebService.get(fullUrl, { headers }).then((response) => {
  //     let _data = response?.data;
  //     if (_data) {
  //       setAllComments(_data?.results);
  //       setTotalComments(_data?.count);
  //     }
  //   });
  // };

  // const handlePostComment = async () => {
  //   if (dataExists(user)) {
  //     let fullUrl = `${apiEndPoints.postAnswer}/`;

  //     let headers = {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${user?.token}`,
  //     };

  //     let payload = {
  //       question: questionDetails.id,
  //       body: userComment,
  //     };
  //     // try {
  //     let response = await WebService.post(fullUrl, payload, {
  //       headers: headers,
  //     });
  //     if (response?.status >= 200 && response?.status < 300) {
  //       setPageNumber(1);
  //       setUserComment("");
  //       loadCommentsData();
  //     } else {
  //       // setError(localString[lang]["errorCreatingQuestion"]);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   loadQuestionData();
  //   if (dataExists(user)) {
  //     setLoggedin(true);
  //   }
  // }, [slug]);

  // useEffect(() => {
  //   if (questionDetails) {
  //     if (questionDetails) {
  //       setDownvoteCount(questionDetails.downvote_count);
  //       setUpvoteCount(questionDetails.upvote_count);
  //       setUserVotetype(questionDetails?.user_votetype);
  //     }

  //     if (questionDetails?.user_votetype === true) {
  //       setUpvoted(true);
  //     } else if (questionDetails?.user_votetype === false) {
  //       setDownvoted(true);
  //     } else {
  //       setUpvoted(false);
  //       setDownvoted(false);
  //     }

  //     loadCommentsData();
  //   }
  // }, [questionDetails, pageNumber]);

  // const enablePostComment = () => {
  //   return !(
  //     // loggedin &&
  //     (userComment !== null && userComment !== "" && questionDetails?.id)
  //   );
  // };

  // const follow = async () => {
  //   if (dataExists(user)) {
  //     let fullUrl = `${apiEndPoints.follow}/`;

  //     let headers = {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${user?.token}`,
  //     };

  //     let payload = {
  //       user: userid,
  //     };
  //     // try {
  //     let response = await WebService.post(fullUrl, payload, {
  //       headers: headers,
  //     });
  //     if (response?.status >= 200 && response?.status < 300) {
  //       setFollowing(true);
  //       // } else {
  //       //   setValidUsername(false);
  //     }
  //   }
  // };
  // const unfollow = async () => {
  //   if (dataExists(user)) {
  //     let fullUrl = `${apiEndPoints.unFollow}/`;

  //     let headers = {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${user?.token}`,
  //     };

  //     let payload = {
  //       user: userid,
  //     };
  //     // try {
  //     let response = await WebService.post(fullUrl, payload, {
  //       headers: headers,
  //     });
  //     if (response?.status >= 200 && response?.status < 300) {
  //       setFollowing(false);
  //       // } else {
  //       //   setValidUsername(false);
  //     }
  //   }
  // };

  let isBot = message?.is_bot;
  if (isBot) {
    return (
      <div className="message-content d-flex w-75">
        <img
          src="/assets/penguin.png"
          className="rounded-circle h-20 me-3"
        ></img>

        <p
          className={`p-3 ${mode === "dark" ? 'text-bg-dark' : 'background-light'}`}
          style={{ whiteSpace: "pre-line", borderRadius: "10px" }}
        >
          {message?.body?.message}
        </p>
      </div>
    );
  } else {
    return (
      <div className="message-content d-flex w-75 float-end justify-content-end">
        <p
          className={`p-3 ${mode === "dark" ? 'text-bg-dark' : 'background-light'}`}
          style={{ whiteSpace: "pre-line", borderRadius: "10px" }}
        >
          {message?.body?.message}
        </p>
        {user?.profile_picture ? (
          <img
            src={user?.profile_picture}
            className="rounded-circle h-20 ms-3"
          ></img>
        ) : (
          <img
            src="/assets/user.png"
            className="rounded-circle h-20 ms-3"
          ></img>
        )}
      </div>
    );
  }
};

export default PenguinChatMessage;
