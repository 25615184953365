import React, { StrictMode, useEffect, useMemo, useState, useRef } from "react";
import { useSearchParams, useParams, useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "./home.scss";
import { dataExists, formatDate, nFormatter } from "../../helpers";
import { localString } from "../../localization/localString";
import moment from "moment";
import axios from "axios";
import { WebService } from "../../services/webServices";
import { apiEndPoints } from "../../services/endpoints";
import { LANGUAGES, scannerOptions } from "../../constants";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import { Helmet } from "react-helmet";
import Pagination from "react-bootstrap/Pagination";
import PaginationComponent from "./Paginator";
import ForumComments from "./ForumComments";
import TopContributers from "./TopContributers";
import Parser from "html-react-parser";
import { Dropdown, ButtonGroup } from "react-bootstrap";
import { Modal } from "react-bootstrap";

const PenguinChatListItem = ({
  lang,
  mode,
  user,
  chat,
  setLatestChat,
  reLoadChatList,
  latestChat,
}) => {
  // const [searchParams, setSearchParams] = useSearchParams();
  const [shareLinkCopied, setShareLinkCopied] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [newTitle, setNewTitle] = useState("");

  const favorite = async () => {
    if (dataExists(user)) {
      let fullUrl = `${apiEndPoints.chatFavorite}/`;

      let headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
      };

      let payload = {
        chat_id: chat?.id,
      };
      let response = await WebService.post(fullUrl, payload, {
        headers: headers,
      });
      if (response?.status >= 200 && response?.status < 300) {
        reLoadChatList();
      } else {
      }
    }
  };

  const rename = async () => {
    if (dataExists(user) && dataExists(newTitle)) {
      let fullUrl = `${apiEndPoints.chatRename}/`;

      let headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
      };

      let payload = {
        chat_id: chat?.id,
        new_name: newTitle,
      };
      let response = await WebService.post(fullUrl, payload, {
        headers: headers,
      });
      if (response?.status >= 200 && response?.status < 300) {
        reLoadChatList();
      } else {
      }
    }
  };

  const deleteChat = async () => {
    if (dataExists(user)) {
      let fullUrl = `${apiEndPoints.chatDelete}/`;

      let headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
      };

      let payload = {
        chat_id: chat?.id,
      };
      let response = await WebService.post(fullUrl, payload, {
        headers: headers,
      });
      if (response?.status >= 200 && response?.status < 300) {
        reLoadChatList(true);
      } else {
      }
    }
  };

  return (
    <div
      key={chat.id}
      onClick={() => {
        setLatestChat && setLatestChat(chat.id);
      }}
      className={`d-flex p-2 chat-list-item ${
        latestChat === chat.id ? "text-body-tertiary" : ""
      }`}
    >
      <p className="cursor-pointer m-0 text-overflow-ellipsis">{chat.name}</p>
      {/* <i className="cursor-pointer bi bi-three-dots-vertical"></i> */}
      <Dropdown as={ButtonGroup} className="three-dot-menu">
        <Dropdown.Toggle
          size="sm"
          variant="link"
          id="dropdown-basic"
          className="three-dot-menu-toggle text-body-emphasis"
        >
          <i className="cursor-pointer bi bi-three-dots-vertical"></i>

          {/* <span style={{ fontSize: '24px', fontWeight: 'bold' }}>⋮</span> */}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              setShowRenameModal(true);
            }}
          >
            Rename
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              favorite();
            }}
          >
            {`${chat.favorite ? "Unfavourite" : "Favourite"}`}
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              let url = `${window.location.origin}/penguin-chat/${chat.id}`;
              // navigator.clipboard.writeText(url);
              setShareLinkCopied(true);
              navigator
                .share({
                  title: "StockPenguins - Free Global Equities Stock Scanner",
                  text: "Hey, I found something Interesting. Check this out",
                  url: url,
                })
                .then(() => {
                  console.log("Successfully shared");
                })
                .catch((error) => {
                  console.error("Something went wrong", error);
                });
            }}
          >
            {`${shareLinkCopied ? "Link Copied" : "Share"}`}
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              deleteChat();
            }}
          >
            Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Modal show={showRenameModal} onHide={() => setShowRenameModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Rename Chat</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Title"
                value={newTitle}
                onChange={(e) => setNewTitle(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowRenameModal(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              rename();
              setShowRenameModal(false);
            }}
          >
            Rename
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PenguinChatListItem;
