import React, { useEffect, useState } from "react";
import Header from "../components/header/Header";
import { THEMES } from "../constants";
import ScrollToTop from "../components/home/ScrollToTop";
import SPSidebar from "../components/header/Sidebar";
import PenguinChat from "../components/home/PenguinChat";

const PenguinChatPage = ({ user, showSearch }) => {
  const [mode, setMode] = useState(null);
  const [loading, setLoading] = useState(true);
  const [starredTickers, setStarredTickers] = useState([]);
  const [isDarkMode, setIsDarkMode] = useState(
    JSON.parse(localStorage.getItem("isDarkMode"))
  );
  const [lang, setLang] = useState(
    JSON.parse(localStorage.getItem("language")) || "en"
  );
  const [forcedLogin, setForcedLogin] = useState(false);

  useEffect(() => {
    let lsIsDarkMode = JSON.parse(localStorage.getItem("isDarkMode"));
    let isDarkMode = lsIsDarkMode === null ? true : lsIsDarkMode;
    setIsDarkMode(isDarkMode);
    setStarredTickers(JSON.parse(localStorage.getItem("starredTickers")));
  }, []);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  useEffect(() => {
    localStorage.setItem("isDarkMode", isDarkMode);
    const htmlElement = document.querySelector("html");
    htmlElement.setAttribute(
      "data-bs-theme",
      isDarkMode ? THEMES.DARK : THEMES.LIGHT
    );
    setMode(isDarkMode ? "dark" : "light");
  }, [isDarkMode]);

  useEffect(() => {
    mode && setLoading(false);
  }, [mode]);

  return (
    <>
      {loading ? (
        <> </>
      ) : (
        <>
          <Header
            toggleDarkMode={toggleDarkMode}
            lang={lang}
            setLang={setLang}
            mode={mode}
            starredTickers={starredTickers}
            user={user}
            forceShowLogin={forcedLogin}
            setForceShowLogin={setForcedLogin}
          />
          <SPSidebar mode={mode} lang={lang} user={user} />
          <section className="section" id="stock-penguins-section">
            <PenguinChat
              lang={lang}
              mode={mode}
              user={user}
              showSearch={showSearch}
              setForceShowLogin={setForcedLogin}
            />
          </section>
          {/* <ScrollToTop /> */}
        </>
      )}
    </>
  );
};

export default PenguinChatPage;
